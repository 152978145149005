import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  list: [],
  user: null,
  error: "",
  loading: false,
};

export const addStaff = createAsyncThunk(
  "user/addStaff",
  async ({ FormData, navigate }) => {
    try {
      const response = await axios({
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `user/register`,
        data: {
          ...FormData,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        if (navigate) {
          navigate("/admin/hr/staffs");
        }
      }
      return {
        data: response.data,
        message: "success",
      };
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error in adding staff. Please try again.");
        return {
          message: "error",
        };
      }
    }
  }
);
export const UploadUserImage = createAsyncThunk(
  "/upload",
  async ({ imgData, FormData, id, value, navigate }, thunkAPI) => {
    try {
      const res = await axios.post("/upload", imgData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.error == false) {
        let totalFiles = res?.data?.files;
        if (value == "Add") {
          if (totalFiles?.image) {
            FormData["image"] = totalFiles?.image?.path;
          }
          if (totalFiles?.firstaid) {
            FormData["firstaid"] = totalFiles?.firstaid?.path;
          }
          if (totalFiles?.dbscheck) {
            FormData["dbscheck"] = totalFiles?.dbscheck?.path;
          }
          if (totalFiles?.safeguard) {
            FormData["safeguarding"] = totalFiles?.safeguard?.path;
          }
          thunkAPI.dispatch(
            addStaff({ FormData: FormData, navigate: navigate })
          );
        }
        if (value == "update") {
          if (totalFiles?.image) {
            FormData["image"] = totalFiles?.image?.path;
          }
          if (totalFiles?.firstaid) {
            FormData["firstaid"] = totalFiles?.firstaid?.path;
          }
          if (totalFiles?.dbscheck) {
            FormData["dbscheck"] = totalFiles?.dbscheck?.path;
          }
          if (totalFiles?.safeguard) {
            FormData["safeguarding"] = totalFiles?.safeguard?.path;
          }
          if (totalFiles?.contractAttachment) {
            FormData["contractAttachment"] =
              totalFiles?.contractAttachment?.path;
          }
          thunkAPI.dispatch(updateUser({ id: id, FormData }));
        }
      }
      return {
        data: res?.data?.files,
        message: "success",
      };
    } catch (error) {
      console.log("error message", error);
      if (error.response && error.response.status === 400) {
        return toast.error(error.response.data.message);
      } else {
        toast.error("Error in adding staff. Please try again.");
        console.log(error.message);
        return {
          message: "error",
        };
      }
    }
  }
);
export const DeleteImage = createAsyncThunk("/upload/delete", async (data) => {
  try {
    axios
      .post("/upload/delete", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {})
      .catch((error) => {});
  } catch {}
});

export const deleteStaff = createAsyncThunk("user/deleteStaff", async (id) => {
  try {
    const resp = await axios({
      method: "patch",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      url: `user/${id}`,
      data: {
        status: false,
      },
    });
    if (resp.status === 200) {
      toast.success(resp.data.message);
    }
    return {
      data: resp.data,
      message: "success",
    };
  } catch (error) {
    if (error.response && error.response.status === 400) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Error in deleting staff. Please try again.");
      return {
        message: "error",
      };
    }
  }
});

export const loadSingleStaff = createAsyncThunk(
  "user/loadSingleStaff",
  async (id) => {
    try {
      const data = await axios.get(`user/${id}`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const loadAllStaff = createAsyncThunk("user/loadAllStaff", async () => {
  try {
    const { data } = await axios.get(`user?query=all`);
    return data;
  } catch (error) {
    console.log(error.message);
  }
});

export const addUser = createAsyncThunk(
  "user/addUser",
  async (values, thunkAPI) => {
    try {
      const { data } = await axios({
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `user/login`,
        data: {
          ...values,
        },
      });
      localStorage.setItem("fullUser", data);
      if (data.roleId !== 2) {
        // Assuming 2 is the role ID for non-admin users
        localStorage.setItem("access-token", data.token);
        localStorage.setItem("role", data.role);
        localStorage.setItem("role", data.roleId);
        localStorage.setItem("user", data.userName);
        localStorage.setItem("email", data.email);
        localStorage.setItem("id", data.id);
        localStorage.setItem("userDetails", JSON.stringify(data)); // Storing as string
        localStorage.setItem("isLogged", true);
        window.location.href = "/admin/dashboard";
        toast.success("login successfully");
      } else {
        toast.error("Staff are not Allowed !");
      }
      return {
        data,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else if (error.response.status === 401) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong!");
      }
      return {
        message: "error",
      };
    }
  }
);

//update user's own profile
export const updateOwnProfile = createAsyncThunk(
  "user/profile",
  async ({ id, values }) => {
    //console.log("Update user own Profile getting at slice:", id, " ", values);
    try {
      const response = await axios({
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `/user/profile/${id}`,
        data: {
          ...values,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
      }
      return {
        data: response.data,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong!");
      }
      return {
        message: "error",
      };
    }
  }
);

// Update User Information
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ id, FormData }) => {
    try {
      const response = await axios({
        method: "put",
        url: `user/${id}`,
        data: {
          ...FormData,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
      }
      return {
        data: response.data,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong!");
      }
      return {
        message: "error",
      };
    }
  }
);
// Forgot Password EMAIL
export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async ({ email }) => {
    try {
      const response = await axios({
        method: "post",
        url: "/user/forgot",
        data: {
          email,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
      }
      return {
        data: response.data,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 404) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error:sending OTP Code to your email");
      }
      return {
        message: "error",
      };
    }
  }
);
// OTP Verification
export const verifyOtp = createAsyncThunk(
  "user",
  async ({ email, resetPasswordToken }) => {
    try {
      const response = await axios({
        method: "post",
        url: "/user/otp",
        data: {
          email,
          resetPasswordToken,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        // navigate(`/admin/auth/resetpassword/${email}`);
      }
      return {
        data: response.data,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 404) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error Verifying OTP");
      }
      return {
        message: "error",
      };
    }
  }
);
// Reset Password ReSet
export const resetpassword = createAsyncThunk(
  "user/restpassword",
  async ({ email, password }) => {
    console.log("slice reset values :", email, "", password);
    try {
      const response = await axios({
        method: "post",
        url: "/user/restpassword",
        data: {
          email,
          password,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
      }
      return {
        data: response.data,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 404) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong!");
      }
      return {
        message: "error",
      };
    }
  }
);
// Change Password Thunk
export const changePassword = createAsyncThunk(
  "user/changepassword",
  async ({ email, oldpassword, newpassword }) => {
    try {
      const response = await axios({
        method: "post",
        url: "/user/changepassword",
        data: {
          email,
          oldpassword,
          newpassword,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
      }
      return {
        data: response.data,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
      return {
        message: "error",
      };
    }
  }
);

// delete User docs
export const DeleteUserdoc = createAsyncThunk(
  "user/user/doc",
  async ({ id, FormData }) => {
    try {
      const response = await axios({
        method: "put",
        url: `/user/doc/${id}`,
        data: {
          ...FormData,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
      }
      return {
        data: response.data,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong!");
      }
      return {
        message: "error",
      };
    }
  }
);
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    // 1) ====== builders for loadAllStaff ======

    builder.addCase(loadAllStaff.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadAllStaff.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload;
    });

    builder.addCase(loadAllStaff.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 2) ====== builders for addStaff ======

    builder.addCase(addStaff.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addStaff.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(addStaff.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // 2) ====== builders for UploadUserImage ======

    builder.addCase(UploadUserImage.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(UploadUserImage.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(UploadUserImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // 3) ====== builders for addUser ======

    builder.addCase(addUser.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addUser.fulfilled, (state, action) => {
      state.loading = false;

      if (!Array.isArray(state.user)) {
        state.user = [];
      }
      const user = [...state.user];
      user.push(action.payload.data);
      state.user = user;
    });

    builder.addCase(addUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 4) ====== builders for updateUser ======

    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 4) ====== builders for DeleteUserdoc ======

    builder.addCase(DeleteUserdoc.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(DeleteUserdoc.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(DeleteUserdoc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
    // 4) ====== builders for loadSingleStaff ======

    builder.addCase(loadSingleStaff.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadSingleStaff.fulfilled, (state, action) => {
      state.loading = false;

      state.user = action.payload.data;
    });

    builder.addCase(loadSingleStaff.rejected, (state, action) => {
      state.loading = false;
      console.log(action.payload);
      state.error = action.payload.message;
    });

    // 5) ====== builders for deleteStaff ======

    builder.addCase(deleteStaff.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteStaff.fulfilled, (state, action) => {
      state.loading = false;

      const filterUser = state.list.filter(
        (staff) => staff.id !== parseInt(action.payload) && staff
      );

      state.list = filterUser;
    });

    builder.addCase(deleteStaff.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 6) ====== builders for Forgot User ======
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      // Handle the fulfilled state, update the state as needed
      state.loading = false;
      state.list = action.payload;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      // Handle the rejected state, update the state as needed
      state.loading = false;
      state.error = action.payload.message;
    });
    // 7) ====== builders for OTP Verification ======

    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      // Handle the fulfilled state, update the state as needed
      state.loading = false;
      state.list = action.payload;
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      // Handle the rejected state, update the state as needed
      state.loading = false;
      state.error = action.payload.message;
    });

    // 8) ====== builders for Reset Password ======

    builder.addCase(resetpassword.fulfilled, (state, action) => {
      // Handle the fulfilled state, update the state as needed
      state.loading = false;
      state.list = action.payload;
    });

    builder.addCase(resetpassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message; // Use action.error.message to get the custom error message
    });

    // 9) ====== builders for updateUser profile ======

    builder.addCase(updateOwnProfile.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateOwnProfile.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(updateOwnProfile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 10) ====== builders for Change Password ======
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      // Handle the fulfilled state, update the state as needed
      state.loading = false;
      state.list = action.payload;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "Password change failed";
    });
  },
});

export default userSlice.reducer;
export const { clearUser } = userSlice.actions;
