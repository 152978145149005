import "bootstrap-icons/font/bootstrap-icons.css";
import { useState } from "react";
import {
  Button,
  Segmented,
  Table,
  Tag,
  Card,
  Form,
  Row,
  Col,
  Select,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { GreenLinkBtn } from "../UI/AllLinkBtn";
import BtnAllSvg from "../UI/Button/btnAllSvg";
import {
  countLeaveApplication,
  loadAllLeaveApplication,
  loadLeaveApplicationByStatus,
} from "../../redux/rtk/features/leave/leaveSlice";
import dayjs from "dayjs";
import BtnViewSvg from "../UI/Button/btnViewSvg";
import ViewBtn from "../Buttons/ViewBtn";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import styles from "./leave.module.css";
import { SearchOutlined } from "@ant-design/icons";
import { MdOutlineSearchOff } from "react-icons/md";
import moment from "moment-timezone";

function CustomTable({ list, total, loading }) {
  const AllEmp = useSelector((state) => state.users.list);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("true");
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [row, setRow] = useState([]);
  const user_id = localStorage.getItem("id");
  const roleId = localStorage.getItem("role");
  const [form] = Form.useForm();
  const [employeeId, setEmployeeId] = useState("");
  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10,
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "EMP_ID",
      key: "employeeId",
      dataIndex: "employeeId",
    },

    {
      title: " Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Leave Type",
      dataIndex: "leaveType",
      key: "leaveType",
    },
    {
      title: "Leave From",
      dataIndex: "leaveFrom",
      key: "leaveFrom",
    },
    {
      title: "Leave To",
      dataIndex: "leaveTo",
      key: "leaveTo",
    },
    {
      title: "Leave Duration",
      dataIndex: "leaveDuration",
      key: "leaveDuration",
    },
    {
      title: "Day type",
      dataIndex: "daytype",
      key: "daytype",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Submitted Date",
      dataIndex: "submitted_date",
      key: "submitted_date",
    },

    {
      title: "Action",
      key: "action",
      dataIndex: "action",
    },
  ];
  //make a onChange function
  const onChange = (value) => {
    setStatus(value);
    dispatch(loadAllLeaveApplication(`userId=${user_id}&status=${value}`));
  };

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const onAllClick = () => {
    dispatch(loadAllLeaveApplication(`query=all&userId=${user_id}`));
    setStatus("all");
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  useEffect(() => {
    if (list) {
      makeRow();
    } else {
      dispatch(loadAllLeaveApplication(`query=all&userId=${user_id}`));
    }
  }, [list]);

  const makeRow = () => {
    const leaveListing = list;
    const data =
      Array.isArray(leaveListing) && leaveListing.length > 0
        ? leaveListing.map((data, id) => ({
            id: data.id,
            employeeId:
              data.user.employeeId == null ? "-" : data.user.employeeId,
            name:
              data.user.firstName + " " + data.user.lastName == null
                ? "-"
                : data.user.firstName + " " + data.user.lastName,
            leaveType:
              data.leaveType == null
                ? "-"
                : data.leaveType === "PaternityLeave(deductible-if-paid)"
                ? "Annual Leave (deductible)"
                : data.leaveType,
            leaveFrom:
              data.leaveFrom == null
                ? "-"
                : dayjs(data.leaveFrom).utc().format("DD-MM-YYYY"),
            leaveTo:
              data.leaveTo == null
                ? "-"
                : dayjs(data.leaveTo).utc().format("DD-MM-YYYY"),
            leaveDuration:
              data.leaveDuration == null
                ? "-"
                : data.leaveDuration > 1
                ? data.leaveDuration + " days"
                : data.leaveDuration == "0.5"
                ? "half"
                : data.leaveDuration + " day",
            daytype: data.daytype == null ? "-" : data.daytype,
            createddate:
              data.createdAt == null
                ? "-"
                : moment.utc(data.createdAt).format("DD-MM-YYYY"),

            submitted_date:
              data.createdAt == null
                ? "-"
                : moment.utc(data.createdAt).format("DD-MM-YYYY"),

            status:
              data.status == null ? (
                "-"
              ) : data.status === "APPROVED" ? (
                <Tag color="green">{data.status?.toUpperCase()}</Tag>
              ) : data.status === "REJECTED" ? (
                <Tag color="red">{data.status?.toUpperCase()}</Tag>
              ) : (
                <Tag color="orange">{data.status?.toUpperCase()}</Tag>
              ),
            action:
              data.id == null ? (
                "-"
              ) : data.id ? (
                <ViewBtn
                  path={`/admin/leave/${data.id}`}
                  text="View"
                  icon={<BtnViewSvg />}
                />
              ) : (
                ""
              ),
          }))
        : leaveListing;
    setRow(data);
  };

  const CSVlist =
    Array.isArray(list) && list.length > 0
      ? list?.map((data) => ({
          id: data.id,
          EMP_ID: data.user.employeeId == null ? "-" : data.user.employeeId,
          Name:
            data.user.firstName + " " + data.user.lastName == null
              ? "-"
              : data.user.firstName + " " + data.user.lastName,
          Leave_Type: data.leaveType == null ? "-" : data.leaveType,
          leave_From:
            data.leaveFrom == null
              ? "-"
              : dayjs(data.leaveFrom).utc().format("DD-MM-YYYY"),
          leaveTo:
            data.leaveTo == null
              ? "-"
              : dayjs(data.leaveTo).utc().format("DD-MM-YYYY"),
          leaveDuration:
            data.leaveDuration == null
              ? "-"
              : data.leaveDuration > 1
              ? data.leaveDuration + " days"
              : data.leaveDuration == "0.5"
              ? "half"
              : data.leaveDuration + " day",
          Day_type: data.daytype == null ? "-" : data.daytype,
          Created_Date:
            data.createdAt == null
              ? "-"
              : dayjs(data.createdAt).format("DD-MM-YYYY"),
          status: data.status == null ? "--" : data.status,
        }))
      : "";

  const handleSearch = () => {
    let data = "";
    if (employeeId != "") {
      if (data.length > 1) {
        data = data + "&";
      }
      data = data + "employeeId=" + employeeId;
    }

    dispatch(loadAllLeaveApplication(`userId=${user_id}&${data}`));
  };

  const ClearFilter = () => {
    dispatch(loadAllLeaveApplication(`query=all&userId=${user_id}`));
    setEmployeeId("");
  };

  function onChangeID(value) {
    setEmployeeId(value);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleTableChange = (pagination) => {
    setPaginationState(pagination);
  };

  return (
    <div className="ant-card p-4 rounded">
      <div className="flex my-2 justify-between items-center">
        <div className="w-50">
          <h4 className="text-[24px] font-[600] mb-2"> Leave Requests</h4>
        </div>
        <div className="flex items-center">
          <Link to={"/admin/addmanualleave"}>
            <Button
              className="mx-5 px-5 text-[15px] font-[400]  bg-[#E42976] text-white border-0"
              type="button"
            >
              Mark Leave
            </Button>
          </Link>
        </div>
      </div>

      <div className="mt-3">
        <div className="card ant-shadow rounded-[20px] w-[100%] border-0 p-0">
          <div className="ant-card-body px-[24px] py-[10px]">
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSearch}
              autoComplete="off"
              initialValues={{
                remember: true,
              }}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="subtitle" className="mb-[0.4rem]">
                    Employee Name:
                  </Typography>
                  <Select
                    showSearch
                    allowClear
                    style={{
                      width: "100%",
                      boxShadow: "0px 0px 5px gray",
                      borderRadius: "5px",
                    }}
                    placeholder="Select a Name"
                    optionFilterProp="children"
                    onChange={onChangeID}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {AllEmp &&
                      AllEmp.map((emp) => (
                        <Select.Option key={emp.id} value={emp?.employeeId}>
                          {emp?.firstName + " " + emp.lastName}
                        </Select.Option>
                      ))}
                  </Select>
                </Col>
                <Col
                  // xs={12} sm={12} md={6} lg={3}
                  xs={{ span: 12, offset: 0 }}
                  sm={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                >
                  <Form.Item>
                    <Button
                      className="w-full text-center mt-[1.85rem] !px-[12px] !py-0 !h-[34px] flex items-center justify-center text-[15px]  bg-[#E42976] text-white border-0"
                      type="submit"
                      shape="round"
                      htmlType="submit"
                      size="large"
                    >
                      <SearchOutlined style={{ fontSize: "20px" }} />
                      Search
                    </Button>
                  </Form.Item>
                </Col>
                <Col
                  // xs={12} sm={12} md={6} lg={3}
                  xs={{ span: 12, offset: 0 }}
                  sm={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                >
                  {" "}
                  <Form.Item>
                    <Button
                      className="w-full justify-center bg-[#FF0000] text-[15px] mt-[1.85rem] !px-[12px] !py-0 !h-[34px] text-white hover:text-white border-0 hover:border-0 flex items-center"
                      shape="round"
                      size="large"
                      onClick={ClearFilter}
                    >
                      <MdOutlineSearchOff style={{ fontSize: "20px" }} />
                      Clear
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      {list && (
        <div className="flex justify-between flex-wrap mb-2">
          <div className="flex">
            <div style={{ marginTop: "27px" }}>
              <ColVisibilityDropdown
                options={columns}
                columns={columns}
                columnsToShowHandler={columnsToShowHandler}
              />
            </div>

            <div style={{ marginTop: "14px" }}>
              <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] ">
                <CSVLink
                  className="m-3 text-white hover:!text-white text-[15px] font-[400]"
                  data={CSVlist}
                  filename="All-leaves-Status"
                >
                  CSV
                </CSVLink>
              </CsvLinkBtn>
            </div>
            <div></div>
          </div>
          <div className="flex justify-end items-center">
            <div>
              <div style={{ marginTop: "25px" }}>
                <button
                  onClick={onAllClick}
                  className="mx-2 text-white hover:!text-white text-center rounded bg-[#19C881] hover:bg-[#19C881] py-1 px-3 text-[15px] font-[400]"
                >
                  <BtnAllSvg size={15} title={"ALL"} />
                </button>
              </div>
            </div>

            <div>
              <Segmented
                style={{ marginTop: "25px" }}
                className="mx-3 text-white hover:!text-white text-center rounded  bg-[#E42976] text-white border-0 px-5 text-[15px] font-[400]"
                size="middle"
                defaultValue={"APPROVED"}
                options={[
                  {
                    label: <span>Approved</span>,
                    value: "APPROVED",
                  },
                  {
                    label: <span>Pending</span>,
                    value: "PENDING",
                  },
                ]}
                value={status}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      )}
      <Card className={styles.cardBody}>
        <Table
          className="text-center"
          columns={columnsToShow}
          dataSource={row}
          loading={loading}
          pagination={{
            current: paginationState.current,
            pageSize: paginationState.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50","100"],
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          onChange={handleTableChange}
          scroll={{ x: 720 }}
        />
      </Card>
    </div>
  );
}
const GetAllLeaves = (props) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.leave.list);
  const loading = useSelector((state) => state.leave.loading);
  const total = useSelector((state) => state.leave.total);
  const user_id = localStorage.getItem("id");

  useEffect(() => {
    dispatch(loadAllLeaveApplication(`query=all&userId=${user_id}`));
    dispatch(countLeaveApplication());
  }, []);

  return (
    <UserPrivateComponent permission={"readAll-leaveApplication"}>
      <div className="card card-custom border-0">
        <div className="card-body">
          <CustomTable list={list} total={total} loading={loading} />
        </div>
      </div>
    </UserPrivateComponent>
  );
};

export default GetAllLeaves;
