import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import React, { useEffect, useState, } from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useParams } from "react-router-dom";
import getUserApi from "../../api/getUserApi";
import { Button, Col, Dropdown, Menu, Row,Modal  } from "antd";
import { LogoutOutlined, UserOutlined, EditFilled, LockFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import AttendancePopup from "../UI/PopUp/AttendancePopup";
import { BiDotsVerticalRounded } from 'react-icons/bi';
const toggler = [
	<svg
		width='20'
		height='20'
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 448 512'
		key={0}>
		<path d='M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z'></path>
	</svg>,
];

function Header({ onPress, collapsed, handleCollapsed }) {
	useEffect(() => window.scrollTo(0, 0));
/* 	const getUserData = async() => {
		//console.log("Getting user data")
		const userDetails = await localStorage.getItem("userDetails")
		console.log("User Details header.js", userDetails)
	} */
	const [email, setEmail] = useState(null);

    useEffect(() => {
        const getUserData = async () => {
            const userDetailsString = await localStorage.getItem("userDetails");
            if (userDetailsString) {
                const userDetails = JSON.parse(userDetailsString);
                setEmail(userDetails.email);
            }
        };

        getUserData();
    }, []);
	
	/* useEffect(() => {
		getUserData();
	}, []) */
	
//	const email = getUserData(); // Call the function to get the email

	const isLogged = localStorage.getItem("isLogged");
	const user = localStorage.getItem("user");
	const id = localStorage.getItem("id");

   // const { email: email } = useParams(); // Extract email parameter from the URL
   //  console.log("...... user name for update user", user)
	//console.log("...... user id for update user", id)


	const items = [
		// {
		// 	key: "1",
		// 	label: (
		// 		<p
		// 			style={{ margin: 0, padding: "0.2rem 0.5rem" }}
		// 			className='flex align-items-center p-3'>
		// 			<UserOutlined style={{ fontSize: "16px" }} />{" "}
		// 			<span className='logout-text font-weight-bold me-2 ms-1'>{user} </span>
		// 		</p>
		// 	),
		// },
		
		{
			key: "2",
			label: (
			  <p style={{ margin: 0, padding: "0.2rem 0.5rem" }} className='flex align-items-center p-3'>
				<Link to={`/admin/auth/profile/${id}`} className={styles.logoutLink}>
				  <EditFilled className='text-[#0723A1]' />
				  <span className='logout-text font-weight-bold'>Profile</span>
				</Link>
			  </p>
			),
		  },
		  {
			key: "3",
			label: (
			  <p style={{ margin: 0, padding: "0.2rem 0.5rem" }} className='flex align-items-center p-3'>
				<Link to={`/admin/auth/changepassword/${email}`} className={styles.logoutLink}>
				  <LockFilled className='text-[#0723A1]' />
				  <span className='logout-text font-weight-bold'>Change Password</span>
				</Link>
			  </p>
			),
		  },
		{
			key: "4",
			label: (
				<p
					style={{ margin: 0, padding: "0.2rem 0.5rem" }}
					className='flex align-items-center p-3'>
					<Link to='/admin/auth/logout' className={styles.logoutLink}>
						<LogoutOutlined className='text-[#0723A1]' />
						<span className='logout-text font-weight-bold'>Log Out</span>
					</Link>
				</p>
			),
		},
	];

	const [isDarkMode, setDarkMode] = useState(false);

	const toggleDarkMode = (checked) => {
		setDarkMode(checked);
	};

	useEffect(() => {
		if (isDarkMode) document.body.className = "dark-theme";
		if (!isDarkMode) document.body.className = "light-theme";
	}, [isDarkMode]);

	return (
		<>
			<Row className="mx-[17px] xl:pt-0 lg:pt-0 md:pt-0 sm:pt-2 xsm:pt-2 xxs:pt-2 xxxs:pt-2 md:flex-row flex-row-reverse
">
				<Col span={12} md={4}>
					<div className={styles.sidebarTogglerPC}>
						{isLogged &&
							React.createElement(
								collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
								{
									className: `${styles.trigger}`,
									onClick: () => handleCollapsed(!collapsed),
								}
							)}
					</div>
				</Col>
				<Col span={12} md={20} className={styles.headerControl}>
					{/* <DarkModeSwitch
						style={{ margin: "1rem" }}
						checked={isDarkMode}
						onChange={toggleDarkMode}
						size={20}
					/> */}

					{!isLogged && (
						<Link to='/admin/auth/login' className='btn-sign-in text-dark'>
							<span></span>
						</Link>
					)}

					{/* {isLogged && (
						<Button
							type='link'
							className={styles.sidebarTogglerMobile}
							onClick={() => onPress()}
							style={{ boxShadow: "none" }}>
							{toggler}
						</Button>
					)} */}
					{isLogged && (
						<div className="flex items-center gap-4">
						{/* <AttendancePopup /> */}
							<Dropdown
								overlay={<Menu items={items} className="rounded-[12px]" />}
								placement='bottomLeft'
								className='user-dropdown bg-[#E42976] text-white p-[1.01rem] custom-shadow'>
								<Button className='user-btn-two flex items-center'>
									<span className='logout-text font-weight-bold me-2 ms-1 text-white'>{user} </span>
									<UserOutlined style={{ fontSize: "16px"}} />
									<BiDotsVerticalRounded style={{ fontSize: "20px" }}/>
								</Button>
							</Dropdown>
						</div>
					)}
				</Col>
				
			</Row>
		</>
	);
}

export default Header;
