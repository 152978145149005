import { Button, Card, Col, Form, Input, Row, Typography } from "antd";
import React, { useState } from "react";
import styles from "./Login.module.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUser } from "../../redux/rtk/features/user/userSlice";
import logoimage from "../../assets/images/comlogo.png";
import logo from "../../assets/images/logo.png";
import { toast } from "react-toastify";
import { FaEyeSlash, FaEye, FaUser, FaLock } from "react-icons/fa";

const Login = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onFinish = async (values) => {
    const resp = await dispatch(addUser(values));
    if (resp.payload.message === "success") {
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoader(false);
    toast.error("Error at login. Please try again");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <Row gutter={16} className="card-row">
        <Col xs={0} sm={12} className={styles.AlignmentImage}>
          <div className={styles.centeredContent}>
            <img src={logoimage} alt="Company Logo" width={350} />
            <p className="mt-3">
              For continued access, please sign in to your account.
            </p>
          </div>
        </Col>

        <Col xs={24} sm={12} className={styles.AlignmentCard}>
          <Card bordered={false} className={`${styles.card} mt-1 mb-3`}>
            <div className={styles.logoImg}>
              <img src={logo} alt="Company Logo" width={100} />
            </div>
            <h3 className="m-3 mb-4 text-center">Sign In</h3>
            <Form
              className={styles.form}
              name="basic"
              wrapperCol={{
                span: 24,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                className="mb-3 px-3"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Your email is Required!",
                  },
                ]}
              >
                <div className={styles.iconInput}>
                  <Input type="text" placeholder="Email" />
                  <FaUser className={styles.icon} color="#E42976" />
                </div>
              </Form.Item>

              <Form.Item
                className="mb-3 px-3"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password Required!",
                  },
                ]}
              >
                <div className={styles.iconInput}>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                  />
                  <FaLock className={styles.icon} color="#E42976" />
                  {showPassword ? (
                    <FaEye
                      className={styles.iconEye}
                      onClick={togglePasswordVisibility}
                    />
                  ) : (
                    <FaEyeSlash
                      className={styles.iconEye}
                      onClick={togglePasswordVisibility}
                    />
                  )}
                </div>
              </Form.Item>

              <Form.Item className="text-right mr-2">
                <Link to={"/admin/auth/forgotpassword"}>
                  <strong>Forgot Password?</strong>
                </Link>
              </Form.Item>
              <Form.Item className={styles.submitBtnContainer}>
                <Button
                  type="submit"
                  className="bg-[#E42976] text-white border-0"
                  htmlType="submit"
                  loading={loader}
                  onClick={() => setLoader(true)}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Login;
