import {
  Card,
  Col,
  Row,
  Typography,
  Form,
  Input,
  Button,
  Radio,
  TimePicker,
  Select,
  Space,
  Checkbox,
} from "antd";
import React, { useEffect, useState } from "react";
import styles from "./meeting.module.css";
import { Calendar } from "react-date-range";
import {
  getDepartments,
  loadSingleDepartment,
} from "../department/departmentApis";
import { loadAllLocation } from "../../redux/rtk/features/location/locationSlice";
import { addMeeting } from "../../redux/rtk/features/Meeting/MeetingSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { loadAllStaff } from "../../redux/rtk/features/user/userSlice";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
const AddMeeting = () => {
  const list = useSelector((state) => state.users.list);
  const user_id = localStorage.getItem("id");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Title } = Typography;
  const [date, setDate] = useState(new Date());
  const [meetingType, setMeetingType] = useState("Physical");
  const [department, setDepartment] = useState([]);
  const [departmentStaff, setDepartmentStaff] = useState([]);
  const location = useSelector((state) => state.location.list);
  const [loader, setLoader] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  // Function to format the date as a string for the input field
  const formatDate = (date) => {
    return date.toLocaleString("default", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  // Handle date change
  const handleDateChange = (newDate) => {
    setDate(newDate);
  };
  // Handle change in Meeting Type
  const handleMeetingTypeChange = (e) => {
    setMeetingType(e.target.value);
  };

  useEffect(() => {
    getDepartments().then((res) => {
      setDepartment(res.concat([{ id: 0, name: "All Departments" }]));
    });
  }, []);
  useEffect(() => {
    dispatch(loadAllLocation({ status: "true" }));
    dispatch(loadAllStaff());
  }, []);

  const handleDepartmentChange = (value) => {
    if (value === 0) {
      setDepartmentStaff(list);
    } else {
      loadSingleDepartment(value).then((res) => {
        setDepartmentStaff(res?.data?.user);
      });
    }
  };

  const onFinish = async (values) => {
    setLoader(true);
    if (date == "" && date == null) {
      return toast.error("Meeting Date is required!");
    }
    if (values.startTime > values.endTime) {
      toast.error("Your meeting end time is less then meeting start time!!!");
      setLoader(false);
      return;
    }
    const infodata = {
      ...values,
      meetingdate: dayjs(date).format("YYYY-MM-DD"),
      meetingType: meetingType,
      assignedBy: user_id,
      userId: selectedItems,
    };

    const resp = await dispatch(addMeeting(infodata));
    if (resp?.payload?.message === "success") {
      setLoader(false);
      form.resetFields();
    } else {
      setLoader(false);
    }
    setLoader(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed at adding Meeting");
    setLoader(false);
  };

  const handleSelectAll = () => {
    const allItemValues = departmentStaff.map((option) => option.id);
    setSelectedItems(allItemValues);
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };
  return (
    <>
      <Card
        className={`${styles.Card} rounded-[20px] ant-shadow overflow-hidden`}
        bordered={false}
      >
        <Title level={4} className={`${styles.Title} m-2 text-center py-2`}>
          Add Meeting
        </Title>
        <Row gutter={16}>
          <Col
            // xl={12} lg={12} md={12} sm={24} xs={24}

            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xl={{ span: 12, offset: 0 }}
            md={{ span: 24, offset: 0 }}
          >
            <div
              className={`${styles.CardCalendar} flex justify-center w-full overflow-auto p-2`}
            >
              <div className="grid gap-4 place-items-center">
                <Calendar
                  date={new Date()}
                  onChange={handleDateChange}
                  // value={date}
                  className={`rounded-[20px] ant-shadow border-0 text-center mt-3 p-3`}
                />
              <p>Selected Date : {dayjs(date).format("DD-MM-YYYY")}</p> 
              </div>
            </div>
          </Col>
          <Col
            //  xl={12} lg={12} md={12} sm={24} xs={24}
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xl={{ span: 12, offset: 0 }}
            md={{ span: 24, offset: 0 }}
          >
            <Form
              className={` p-3`}
              layout="horizontal"
              name="basic"
              form={form}
              eventKey="meeting-form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              labelAlign="left"
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 16,
              }}
            >
              <Form.Item
                labelCol={{
                  xs: { span: 12 },
                  sm: { span: 10 },
                  md: { span: 10 },
                  lg: { span: 9 },
                  xl: { span: 9 },
                }}
                style={{ marginBottom: "20px" }}
                label="Department:"
                name="departmentId"
                rules={[
                  {
                    required: true,
                    message: "Department Required!",
                  },
                ]}
              >
                <Select
                  onChange={handleDepartmentChange}
                  placeholder="Select Department"
                  style={{
                    border: "none",
                  }}
                >
                  {department &&
                    department
                      .slice()
                      .reverse()
                      ?.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{
                  xs: { span: 12 },
                  sm: { span: 10 },
                  md: { span: 10 },
                  lg: { span: 9 },
                  xl: { span: 9 },
                }}
                style={{ marginBottom: "20px" }}
                label="Add Staff:"
                // name="userId"
                rules={[
                  {
                    required: true,
                    message: "Staff is Required!",
                  },
                ]}
              >
                <div className="">
                  <Checkbox
                    onChange={(e) =>
                      e.target.checked ? handleSelectAll() : handleDeselectAll()
                    }
                  >
                    Select All Staff
                  </Checkbox>
                  <Select
                    allowClear
                    loading={departmentStaff?.user === 0}
                    mode="multiple"
                    placeholder="Select Staff"
                    maxTagCount="responsive"
                    style={{
                      border: "none",
                      width: "100%",
                    }}
                    value={selectedItems}
                    onChange={(values) => setSelectedItems(values)}
                  >
                    {departmentStaff &&
                      departmentStaff?.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option?.firstName + " " + option.lastName}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </Form.Item>
              <Form.Item
                labelCol={{
                  xs: { span: 12 },
                  sm: { span: 10 },
                  md: { span: 10 },
                  lg: { span: 9 },
                  xl: { span: 9 },
                }}
                style={{ marginBottom: "20px" }}
                label="Meeting Date:"
                // name="meetingDate"
                // rules={[
                //   {
                //     required: true,
                //     message: "Meeting Date Required!",
                //   },
                // ]}
              >
                <h6
                  size="small"
                  style={{
                    border: "none",
                    color: "black",
                    width: "100%",
                    padding: "6px 50px",
                  }}
                  className="text-center"
                >
                  {formatDate(date)}
                </h6>
              </Form.Item>
              <Form.Item
                labelCol={{
                  xs: { span: 12 },
                  sm: { span: 10 },
                  md: { span: 10 },
                  lg: { span: 12 },
                  xl: { span: 9 },
                }}
                style={{ marginBottom: "0px" }}
                label="Time:"
              >
                <div className="flex justify-between ">
                  <Form.Item
                    style={{
                      width: "50%",
                      // border: "none",
                    }}
                    name="startTime"
                    rules={[
                      {
                        required: true,
                        message: "start Time Required!",
                      },
                    ]}
                  >
                    <TimePicker
                      className="mr-1"
                      size="small"
                      style={{
                        // border: "none",
                        // backgroundColor: "#0723A1",
                        color: "white",
                      }}
                      name="startTime"
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      width: "50%",
                      // border: "none",
                    }}
                    name="endTime"
                    rules={[
                      {
                        required: true,
                        message: "End Time Required!",
                      },
                    ]}
                  >
                    <TimePicker
                      className="ml-1"
                      size="small"
                      style={
                        {
                          // border: "none",
                        }
                      }
                      name="endTime"
                    />
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item
                labelCol={{
                  xs: { span: 12 },
                  sm: { span: 10 },
                  md: { span: 10 },
                  lg: { span: 12 },
                  xl: { span: 9 },
                }}
                style={{ marginBottom: "15px" }}
                label="Meeting Type:"
                name="meetingType"
                initialValue="Physical"
                rules={[
                  {
                    required: true,
                    message: "Meeting Type Required!",
                  },
                ]}
              >
                <Radio.Group onChange={handleMeetingTypeChange}>
                  <Radio value="Physical">Physical</Radio>
                  <Radio value="Virtual">Virtual</Radio>
                  <Radio value="Both">Both</Radio>
                </Radio.Group>
              </Form.Item>

              {/* Conditional rendering based on meetingType */}
              {meetingType === "Physical" && (
                <Form.Item
                  labelCol={{
                    xs: { span: 12 },
                    sm: { span: 10 },
                    md: { span: 10 },
                    lg: { span: 9 },
                    xl: { span: 9 },
                  }}
                  label="Location:"
                  name="locationId"
                  rules={[
                    {
                      required: true,
                      message: "Location is Required!",
                    },
                  ]}
                >
                  <Select
                    // mode="multiple" // Allow multiple selections
                    placeholder="Select Location"
                    style={{
                      border: "none",
                      // boxShadow: "0px 0px 5px #d1cccc",
                    }}
                  >
                    {location &&
                      location?.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.locationName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              {meetingType === "Virtual" && (
                <Form.Item
                  labelCol={{
                    xs: { span: 12 },
                    sm: { span: 10 },
                    md: { span: 10 },
                    lg: { span: 9 },
                    xl: { span: 9 },
                  }}
                  label="Link:"
                  name="meetingLink"
                  rules={[
                    {
                      required: true,
                      message: "Meeting Link Required!",
                    },
                  ]}
                >
                  <Input
                    size="small"
                    placeholder="Meeting Link"
                    style={{
                      border: "none",
                      boxShadow: "0px 0px 5px  #d1cccc",
                    }}
                  />
                </Form.Item>
              )}
              {/* Conditional rendering based on meetingType */}
              {meetingType === "Both" && (
                <>
                  <Form.Item
                    labelCol={{
                      xs: { span: 12 },
                      sm: { span: 10 },
                      md: { span: 10 },
                      lg: { span: 9 },
                      xl: { span: 9 },
                    }}
                    label="Location:"
                    name="locationId"
                    rules={[
                      {
                        required: true,
                        message: "Location is Required!",
                      },
                    ]}
                  >
                    <Select
                      // mode="multiple" // Allow multiple selections
                      placeholder="Select Location"
                      style={{
                        border: "none",
                        boxShadow: "0px 0px 5px #d1cccc",
                      }}
                    >
                      {location &&
                        location?.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.locationName}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    labelCol={{
                      xs: { span: 12 },
                      sm: { span: 10 },
                      md: { span: 10 },
                      lg: { span: 9 },
                      xl: { span: 9 },
                    }}
                    label="Link:"
                    name="meetingLink"
                    rules={[
                      {
                        required: true,
                        message: "Meeting Link Required!",
                      },
                    ]}
                  >
                    <Input
                      size="small"
                      placeholder="Meeting Link"
                      style={{
                        border: "none",
                        boxShadow: "0px 0px 5px  #d1cccc",
                      }}
                    />
                  </Form.Item>
                </>
              )}

              <div className={`text-center mt-10`}>
                <Button
                  onClick={() => setLoader(true)}
                  loading={loader}
                  className="px-10 bg-[#E42976] text-white border-0"
                  size="medium"
                  key="submit"
                  type="submit"
                  htmlType="submit"
                >
                  Schedule
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default AddMeeting;
