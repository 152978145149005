import { Card, Col, List, Row } from "antd";
import Loader from "../loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  clearLeaveApplication,
  loadSingelLeaveApplication,
  LeaveApplicationStatus, leaveCountHistory
} from "../../redux/rtk/features/leave/leaveSlice";
import tw from "tailwind-styled-components";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../page-header/PageHeader";
import dayjs from "dayjs";
import ReviewLeavePopup from "../UI/PopUp/ReviewLeavePopup";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import styles from './leave.module.css';
import { IoIosShareAlt } from 'react-icons/io';
import React, { useState } from 'react';
import { Modal, Table, Button } from 'antd';
import { GrDownload } from "react-icons/gr";
import { FaDownload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { loadSingleStaff } from "../../redux/rtk/features/user/userSlice";
function DataTableModal({ data }) {
  const [modalVisible, setModalVisible] = useState(false);
  const columns = [
    // Define your table columns here
    {
      id: 1,
      title: "Emp-Id",
      dataIndex: "user",
      key: "user",
      render: (user) => (user?.employeeId == null ? "-" : user?.employeeId),
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "user",
      key: "user",
      render: (user) => user?.firstName + " " + user.lastName,
    },
    {
      id: 3,
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("DD-MM-YYYY"),
    },
    {
      id: 4,
      title: "Department",
      dataIndex: "user",
      key: "user",
      render: (user) =>
        user?.department == null ? "none" : user?.department?.name,
    },
  ];

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div>
      <Link type="primary" onClick={showModal}>
        View Details
      </Link>
      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Staff on Leave
          </div>
        }
        className="global-modal-custom"
        visible={modalVisible}
        onCancel={closeModal}
        footer={false}
      >
        <Table
          className={styles.TableStyle}
          dataSource={data}
          columns={columns}
          pagination={false}
        />
      </Modal>
    </div>
  );
}

const DetailLeave = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams("id");
  const leave = useSelector((state) => state.leave.leave);
  const countHistory = useSelector((state) => state.leave.leaveCountHistory);
  const dispatch = useDispatch();
  const [updateLeave, setUpdateLeave] = useState(false);
  const user_id = localStorage.getItem("id");
  const user = localStorage.getItem("user");
  const role = localStorage.getItem("role");
  const roleId = localStorage.getItem("role");
  const fullData = JSON.parse(localStorage.getItem("userDetails"));
  const current_user = user_id;

  useEffect(() => {
    dispatch(leaveCountHistory());
  }, []);

  useEffect(() => {
    dispatch(loadSingelLeaveApplication(id));
    return () => {
      dispatch(clearLeaveApplication());
    };
  }, []);

  function handleLeaveStatus(status) {
    const values = {
      acceptLeaveBy: user_id,
      acceptLeaveFrom: leave?.leaveFrom,
      acceptLeaveTo: leave?.leaveTo,
      reason: leave?.reason,
      status: status,
    };
    dispatch(
      LeaveApplicationStatus({ id: id, values: values, navigate: navigate })
    );
  }

  return (
    <div>
      <PageTitle title="Back" />
      <UserPrivateComponent permission={"readSingle-leaveApplication"}>
        <div className={styles.container}>
          <Card className={styles.cardStyle}>
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className={`${styles.title} text-center `}>
                  <h2>Application Details</h2>
                </div>
                <strong className="flex justify-center m-2">
                  <p style={{ color: "red" }}>
                    {leave == null
                      ? "0"
                      : leave?.approvedLeaveCount}{" "}
                    Leaves are already approved for same date.
                  </p>
                  <DataTableModal data={leave?.approvedLeaveApplications} />
                  <IoIosShareAlt
                    className="ml-2"
                    style={{ fontSize: "20px" }}
                  />
                </strong>
                {leave ? (
                  <>
                    <List className="list-inside list-none border-none px-5  ">
                      <div className="flex justify-between bg-[#F3F4F6] mb-1 ">
                        <ListItem>
                          Emp ID :{" "}
                          <TextInside>{leave?.user.employeeId}</TextInside>
                        </ListItem>
                        <ListItem>
                          Emp Name :{" "}
                          <TextInside>
                            {(
                              leave?.user.firstName +
                              " " +
                              leave?.user.lastName
                            ).toUpperCase()}
                          </TextInside>
                        </ListItem>
                      </div>

                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem className="!w-full">
                          Leave Type :{" "}
                          <TextInside>{leave.leaveType}</TextInside>
                        </ListItem>
                        {/* <ListItem>
													Leave Category : <TextInside>{leave.leaveType}</TextInside>
												</ListItem> */}
                      </div>
                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                          Leave From :{" "}
                          <TextInside>
                            {dayjs(leave.leaveFrom).utc().format("DD-MM-YYYY")}
                          </TextInside>
                        </ListItem>

                        <ListItem>
                          Leave To :{" "}
                          <TextInside>
                            {dayjs(leave.leaveTo).utc().format("DD-MM-YYYY")}
                          </TextInside>
                        </ListItem>
                      </div>

                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                          Duration :{" "}
                          <TextInside>
                            {leave?.leaveDuration == 0.5
                              ? "Half Day"
                              : leave?.leaveDuration > 1
                                ? leave?.leaveDuration + " Days"
                                : leave?.leaveDuration + " Day"}
                          </TextInside>
                        </ListItem>
                        <ListItem>
                          Attachments :{" "}
                          <TextInside>
                            {leave?.attachment == null ||
                              leave?.attachment == "null" ? (
                              "No Attachment"
                            ) : (
                              <>
                                <a
                                  href={leave.attachment}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-blue-500 flex items-center"
                                >
                                  Download&nbsp;
                                  <FaDownload className={styles.color} />
                                </a>
                              </>
                            )}
                          </TextInside>
                        </ListItem>
                      </div>

                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                          Leave Status :{" "}
                          <TextInside>
                            {leave.status === "pending" ? (
                              <span className="text-yellow-500">
                                {leave.status.toUpperCase()}
                              </span>
                            ) : leave.status === "accepted" ? (
                              <span className="text-green-500">
                                {leave.status.toUpperCase()}
                              </span>
                            ) : (
                              <span className="text-red-500">
                                {leave.status.toUpperCase()}
                              </span>
                            )}
                          </TextInside>
                        </ListItem>

                        <ListItem>
                          Leave Accepted By :{" "}
                          <TextInside>
                            {(leave?.approvedLeaveApplications[0]?.acceptLeaveBy?.firstName || "ON") +
                              " " +
                              (leave?.approvedLeaveApplications[0]?.acceptLeaveBy?.lastName || "REVIEW")}
                          </TextInside>
                        </ListItem>
                      </div>
                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                          Leave Accepted From :{" "}
                          <TextInside>
                            {leave.acceptLeaveFrom
                              ? dayjs(leave.acceptLeaveFrom).format(
                                "DD-MM-YYYY"
                              )
                              : "ON REVIEW"}
                          </TextInside>
                        </ListItem>
                        <ListItem>
                          Leave Accepted To :{" "}
                          <TextInside>
                            {leave.acceptLeaveTo
                              ? dayjs(leave.acceptLeaveTo).format("DD-MM-YYYY")
                              : "ON REVIEW"}
                          </TextInside>
                        </ListItem>
                      </div>


                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                        Leave Note:
                          <TextInside>
                          {leave.reason ? leave.reason : "No Comments"}
                          </TextInside>
                        </ListItem>
                        <ListItem>
                          submitted Date :{" "}
                          <TextInside>
                            {leave.createdAt
                              ? dayjs(leave.createdAt).format("DD-MM-YYYY")
                              : "ON REVIEW"}
                          </TextInside>
                        </ListItem>
                      </div>
                    </List>

                    <div className="flex justify-center ">
                      <div className="flex justify-center mt-5">
                        {roleId !== 2 ? (
                          leave.leavecategory == "unpaid" && roleId == 5 ? (
                            ""
                          ) : (
                            <>
                              <UserPrivateComponent
                                permission={"update-leaveApplication"}
                              >
                                {leave?.status === "PENDING" && (
                                  <div className="flex justify-center items-center">
                                    <ReviewLeavePopup />
                                  </div>
                                )}
                              </UserPrivateComponent>
                              <UserPrivateComponent
                                permission={"update-leaveApplication"}
                              >
                                {leave?.status === "PENDING" && (
                                  <div
                                    className={`${styles.Button} flex justify-center items-center`}
                                  >
                                    <Button
                                      className=" bg-[#E42976] text-white border-0"
                                      type="submit"
                                      htmlType="submit"
                                      block
                                      onClick={(e) =>
                                        handleLeaveStatus("APPROVED")
                                      }
                                    >
                                      Approved
                                    </Button>
                                  </div>
                                )}
                                {roleId == 5 ? (
                                  ""
                                ) : leave?.status === "APPROVED" ? (
                                  <div
                                    className={`${styles.Button} flex justify-center items-center`}
                                  >
                                    <Button
                                      className=" bg-[#E42976] text-white border-0"
                                      type="submit"
                                      htmlType="submit"
                                      block
                                      onClick={(e) => setUpdateLeave(true)}
                                    >
                                      Update
                                    </Button>
                                  </div>
                                ) : (
                                  leave?.status === "REJECTED" && (
                                    <div
                                      className={`${styles.Button} flex justify-center items-center`}
                                    >
                                      <Button
                                        className=" bg-[#E42976] text-white border-0"
                                        type="submit"
                                        htmlType="submit"
                                        block
                                        onClick={(e) => setUpdateLeave(true)}
                                      >
                                        Update
                                      </Button>
                                    </div>
                                  )
                                )}
                              </UserPrivateComponent>
                            </>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <List className='list-inside list-none border-none px-5  '>
													<div className="flex justify-between bg-[#F3F4F6] mb-1 ">
													<ListItem>
														Emp ID :{" "}
														<TextInside>
															{(
																leave?.user.employeeId
															)}
														</TextInside>
													</ListItem>
													<ListItem>
														Emp Name :{" "}
														<TextInside>
															{(
																leave?.user.firstName +
																" " +
																leave?.user.lastName
															).toUpperCase()}
														</TextInside>
													</ListItem>

												</div>

												<div className="flex justify-between bg-[#F3F4F6] mb-1">
													<ListItem>
														Leave Type : <TextInside>{leave.leaveType}</TextInside>
													</ListItem>
													<ListItem>
														Leave Category : <TextInside>{leave.leaveType}</TextInside>
													</ListItem>
												</div>
												<div className="flex justify-between bg-[#F3F4F6] mb-1">
													<ListItem>
														Leave From :{" "}
														<TextInside>
															{dayjs(leave.leaveFrom).format("DD-MM-YYYY")}
														</TextInside>
													</ListItem>

													<ListItem>
														Leave To :{" "}
														<TextInside>
															{dayjs(leave.leaveTo).format("DD-MM-YYYY")}
														</TextInside>
													</ListItem>
												</div>

												<div className="flex justify-between bg-[#F3F4F6] mb-1">
													<ListItem>
														Duration :{" "}
														<TextInside className='text-red-500'>
															{leave.leaveDuration}
														</TextInside>
													</ListItem>
													<ListItem>
														Attachments :{" "}
														<TextInside>
															{leave.attachment ? (
																<a
																	href={leave.attachment}
																	target='_blank'
																	rel='noreferrer'
																	className='text-blue-500'>
																	Download
																</a>
															) : (
																"No Attachment"
															)}
														</TextInside>
														&nbsp; &nbsp; <FaDownload className={styles.color} />


													</ListItem>

												</div> 

													<div className="flex justify-between bg-[#F3F4F6] mb-1">

													<ListItem>
														Leave Status :{" "}
														<TextInside>
															{leave.status === "pending" ? (
																<span className='text-yellow-500'>
																	{leave.status.toUpperCase()}
																</span>
															) : leave.status === "accepted" ? (
																<span className='text-green-500'>
																	{leave.status.toUpperCase()}
																</span>
															) : (
																<span className='text-red-500'>
																	{leave.status.toUpperCase()}
																</span>
															)}
														</TextInside>
													</ListItem>

													<ListItem>
														Leave Accepted By :{" "}
														<TextInside className='text-green-500'>
															{(leave.acceptLeaveBy?.firstName || "ON") +
																" " +
																(leave.acceptLeaveBy?.lastName || "REVIEW")}
														</TextInside>
													</ListItem>

												</div>
												<div className="flex justify-between bg-[#F3F4F6] mb-1">

													<ListItem>
														Leave Accepted To :{" "}
														<TextInside>
														{leave.acceptLeaveTo
														? dayjs(leave.acceptLeaveTo).format("DD-MM-YYYY")
														: "ON REVIEW"}
														</TextInside>
													</ListItem>

													<ListItem>
														Leave Accepted From :{" "}
														<TextInside>
															{leave.acceptLeaveFrom
																? dayjs(leave.acceptLeaveFrom).format("DD-MM-YYYY")
																: "ON REVIEW"}
														</TextInside>
													</ListItem>
												</div> 
													<div className="flex justify-between">
											<ListItem>
												Review Comment :{" "}
												<TextInside>{leave.reviewComment || "No comment"}</TextInside>
											</ListItem>

											<ListItem>
												Attachment :{" "}
												<TextInside>
													{leave.attachment ? (
														<a
															href={leave.attachment}
															target='_blank'
															rel='noreferrer'
															className='text-blue-500'>
															Download
														</a>
													) : (
														"No Attachment"
													)}
												</TextInside>
											</ListItem>
										</div>

													<div className=" bg-[#F3F4F6] text-left p-3">
													<strong style={{ color: "#47515E" }}>Leave Note:</strong>
													<p>{leave.reason ? leave.reason
														: "No Comments"}</p>

												</div>

											</List> */}
                    </div>
                  </>
                ) : (
                  <Loader />
                )}
              </Col>
            </Row>
          </Card>
        </div>
        <Modal
          title={
            <div className={`${styles.title} text-center p-3`}>
              <h2>Update Leave Status</h2>
            </div>
          }
          visible={updateLeave}
          onCancel={(e) => setUpdateLeave(false)}
          footer={false}
        >
          <div className="flex items-center gap-4 justify-center">
            {leave?.status === "APPROVED" ? (
              <div
                className={`${styles.Button} flex justify-center items-center`}
              >
                <Button
                  className="text-white !bg-[red] hover:border-0 border-0"
                  // type='primary'
                  htmlType="submit"
                  block
                  onClick={(e) => handleLeaveStatus("REJECTED")}
                >
                  Rejected
                </Button>
              </div>
            ) : (
              leave?.status === "REJECTED" && (
                <div
                  className={`${styles.Button} flex justify-center items-center`}
                >
                  <Button
                    className=" bg-[#E42976] text-white border-0"
                    type="submit"
                    htmlType="submit"
                    block
                    onClick={(e) => handleLeaveStatus("APPROVED")}
                  >
                    Approved
                  </Button>
                </div>
              )
            )}

            <div
              className={`${styles.Button} flex justify-center items-center`}
            >
              <Button
                className="text-black bg-[#e0e0e0] hover:border-0 border-0"
                type="secondary"
                htmlType="submit"
                block
                onClick={(e) => setUpdateLeave(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </UserPrivateComponent>
    </div>
  );

  // "reviewComment": null,
  // "attachment": null,
};

const ListItem = tw.li`
  text-sm
  text-gray-600
  font-semibold
  py-2
  px-2
  bg-gray-100
  mb-1.5
  rounded
  w-96
  flex
  justify-start
  text-left

`;

const TextInside = tw.p`
  ml-2
  text-sm
  text-gray-900
`;
export default DetailLeave;

