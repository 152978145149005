import React, {Fragment, useEffect} from "react";
import {
  UsergroupAddOutlined,
  TeamOutlined,
  UsergroupDeleteOutlined,
  UserSwitchOutlined,
  DollarOutlined,
} from "@ant-design/icons";
//import "./style.css";
import styles from "./style.module.css";
import { Card, Col, Row, Statistic } from "antd";
import { AiOutlineUser } from "react-icons/ai";
import {loadAllAttendance} from "../../../redux/rtk/features/attendance/attendanceSlice";
const style = {
  // background: '#0092ff',
  padding: "0px 0",
};
const NewDashboardCard = ({ information, data }) => {
  useEffect(() => {
    setTimeout(()=>{
      console.log(data, "very nice", information)
    }, 5000)
  }, []);
  return (
    <Fragment>
      <div>
        <Card className={`${styles.cardBody}`}>
          <div className={`${styles.TitleStyles} mx-3`}>
            <h2>Leave Stats</h2>
            <h5>Leave Summary</h5>
          </div>
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" xs={24} sm={24} md={6} lg={6} xl={6}>
              <div className={`${styles.StyleP}`} style={style}>
                <div className={styles.Div}>
                  <AiOutlineUser className={styles.icon} />
                  <p>
                    Total <br /> User{" "}
                  </p>
                  <h2>
                    {information?.totalUsers == null
                      ? 0
                      : information?.totalUsers}
                  </h2>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={6} lg={6} xl={6}>
              <div className={`${styles.StyleA}`} style={style}>
                <div className={styles.Div}>
                  <AiOutlineUser className={styles.iconA} />
                  <p>
                    {" "}
                    Today's <br /> Present{" "}
                  </p>
                  <h2>{data?.totalPresent == null ? 0 : data?.totalPresent}</h2>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={6} lg={6} xl={6}>
              <div className={`${styles.Style}`} style={style} >

                <div className={styles.Div}>
                  <AiOutlineUser className={styles.iconR} />
                  <p>
                    Today's <br /> Absent{" "}
                  </p>
                  <h2>{data?.totalAbsent == null ? 0 : data?.totalAbsent}</h2>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={6} lg={6} xl={6}>
              <div className={`${styles.StyleR}`} style={style}>
                <div className={styles.Div}>
                  <AiOutlineUser className={styles.iconP} />
                  <p>
                    {" "}
                    Today's <br /> Leaves{" "}
                  </p>
                  <h2>{data?.totalLeaves == null ? 0 : data?.totalLeaves}</h2>
                </div>
              </div>
            </Col>
          </Row>

          {/* <Row gutter={[16, 16]}>

						<Col className={`${styles.Style}`} xs={24} sm={24} md={6} lg={6} xl={6}>
							<Card className='ant-shadow txt-color-2 rounded-[15px]' bordered={false}>
								<Statistic
									title={<p className='text-xl  txt-color-2'> TOTAL USERS </p>}
									loading={!information}
									value={information?.totalUsers}
									valueStyle={{
										color: "#10b981",
									}}
									prefix={
										<TeamOutlined
											className={`${styles.icon} mr-4`}
											style={{ fontSize: "35px" }}
										/>
									}
								/>
							</Card>

						</Col>

						<Col className={`${styles.StyleA}`} xs={24} sm={24} md={6} lg={6} xl={6}>

							<Card className='ant-shadow txt-color-2 rounded-[15px]' bordered={false}>

								<Statistic
									title={<p className='text-xl  txt-color-2'> TODAY ABSENT</p>}
									loading={!data}
									value={data?.totalAbsent}
									valueStyle={{
										color: "#f43f5e",
									}}
									prefix={
										<UsergroupDeleteOutlined
											className='mr-4'
											style={{ fontSize: "35px" }}
										/>
									}
								/>
							</Card>
						</Col>

						<Col className={`${styles.StyleR}`} xs={24} sm={24} md={6} lg={6} xl={6}>

							<Card className='ant-shadow txt-color-2 rounded-[15px]' bordered={false}>
								<Statistic
									title={
										<p className='text-xl  txt-color-2'> TODAY PRESENT </p>
									}
									loading={!data}
									value={data?.totalPresent}
									valueStyle={{
										color: "#0891b2",
									}}
									prefix={
										<UsergroupAddOutlined
											className='mr-4'
											style={{ fontSize: "35px" }}
										/>
									}
								/>
							</Card>
						</Col>

						<Col className={`${styles.StyleP}`} xs={24} sm={24} md={6} lg={6} xl={6}>

							<Card className='ant-shadow txt-color-2 rounded-[15px]' bordered={false}>
								<Statistic
									title={<p className='text-xl  txt-color-2'>TODAY ON LEAVE</p>}
									loading={!data}
									value={data?.totalLeaves}
									valueStyle={{
										color: "#a855f7",
									}}
									prefix={
										<UserSwitchOutlined
											className='mr-4'
											style={{ fontSize: "35px" }}
										/>
									}
								/>
							</Card>
						</Col>
					</Row> */}
        </Card>
      </div>
    </Fragment>
  );
};

export default NewDashboardCard;
