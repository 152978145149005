import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Sidenav from "../Sidenav/Sidenav";
import Footer from "./Footer";
import Header from "./Header";
import styles from "./Main.module.css";
import QuickLinks from "./QuickLinks";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import checkTokenExp from "../../utils/checkTokenExp";
import { Affix, Layout, Drawer } from "antd";
const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const dispatch = useDispatch();
  const location = useLocation();

  // get the token from local storage and decode JWT Token and get the user id from the token
  const token = localStorage.getItem("access-token");

  useEffect(() => {
    if (token) {
      checkTokenExp(token);
      const id = jwtDecode(token).sub;
    }
  }, []);

  const [collapsed, setCollapsed] = useState(false);
  const [sideNavOpenKeys, setSideNavOpenKeys] = useState("");

  const sideNavOpenKeysHandler = (val) => {
    setSideNavOpenKeys(val);
  };

  const handleCollapsed = (val) => {
    setCollapsed(val);
  };

  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#0622a1");
  const [fixed, setFixed] = useState(false);

  const openDrawer = () => setVisible(!visible);

  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  const isAuthScreen =
    location.pathname.includes("/admin/auth/login") ||
    location.pathname.includes("/admin/auth/forgotpassword") ||
    location.pathname.includes("/admin/auth/otp") ||
    location.pathname.includes("/admin/auth/resetpassword");

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (isAuthScreen) {
    // Render only the content without header and footer on auth screens
    return <div className={styles.authContainer}>{children}</div>;
  }


  return (
    <Layout className={styles.mainLayout}>
      {isLogged && (
        <Drawer
          title={false}
          placement={placement === "right" ? "left" : "right"}
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
          key={placement === "right" ? "left" : "right"}
          width={225}
        >
          <Layout>
            <Sider
              trigger={null}
              width={225}
              style={{ backgroundColor: "#0622a1" }}
              className={styles.siderDrawer}
            >
              <Sidenav
                style={{ backgroundColor: "#0622a1" }}
                color={sidenavColor}
                sideNavOpenKeys={sideNavOpenKeys}
              />
            </Sider>
          </Layout>
        </Drawer>
      )}

      {isLogged && (
        <div style={{ backgroundColor: "#0622a1" }}>
          <Sider
            breakpoint="lg"
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={225}
            style={{
              borderRadius: "15px",
              margin: "4px",
              display: collapsed ? "block" : "block",
              ...(window.innerWidth <= 600 && collapsed
                ? { display: "none" }
                : {}),
            }}
            className={`${styles.siderMain} scrollbar-hide`}
          >
            {collapsed ? (
              ""
            ) : (
              <div>
                <h2
                  className="text-white text-center mb-2"
                  style={{ width: "40%", marginLeft: "60px" }}
                >
                  <img src="/images/2.png" alt="Logo" />
                </h2>
              </div>
            )}
            {isLogged && (
              <Sidenav color={sidenavColor} sideNavOpenKeys={sideNavOpenKeys} />
            )}
          </Sider>
        </div>
      )}
      <Layout
        className={
          isLogged
            ? collapsed
              ? styles.mainLayoutUncollapse
              : styles.mainLayoutCollapse
            : styles.mainLayoutMarginLeftZero
        }
      >
        {fixed ? (
          <Affix>
            <AntHeader>
              <Header
                onPress={openDrawer}
                name={location.pathname}
                subName={location.pathname}
                handleSidenavColor={handleSidenavColor}
                handleFixedNavbar={handleFixedNavbar}
                collapsed={collapsed}
                handleCollapsed={handleCollapsed}
                isLogged={isLogged}
              />
            </AntHeader>
          </Affix>
        ) : (
          <AntHeader>
            <Header
              onPress={openDrawer}
              name={location.pathname}
              subName={location.pathname}
              handleSidenavColor={handleSidenavColor}
              handleFixedNavbar={handleFixedNavbar}
              collapsed={collapsed}
              handleCollapsed={handleCollapsed}
            />
          </AntHeader>
        )}
        {isLogged &&
          (location.pathname.trim() === "dashboard" ||
            location.pathname.trim() === "") && (
            <QuickLinks
              sideNavOpenKeys={sideNavOpenKeys}
              sideNavOpenKeysHandler={sideNavOpenKeysHandler}
            />
          )}
        <Content>{children}</Content>
        {!isAuthScreen && <Footer />}
      </Layout>
    </Layout>
  );
}

export default Main;
