import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changePassword } from "../../redux/rtk/features/user/userSlice";
import { toast } from "react-toastify";
import { Form, Button, Input, Row, Col, Card } from "antd";
import { useParams } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import BtnLoader from "../loader/BtnLoader";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { email: email } = useParams(); // Extract email parameter from the URL
  const navigation = useNavigate();

  const initialValues = {
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
    submit: null,
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().max(255).required("Old Password is required"),
    newPassword: Yup.string().max(255).required("New Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .max(255)
      .required("Confirm Password is required"),
  });

  const handleSubmit = async ({ resetForm }) => {
    if (values.oldpassword == "") {
      return toast.error("Old Password is required");
    }
    if (values.newpassword == "") {
      return toast.error("New Password is required");
    }
    if (values.confirmpassword == "") {
      return toast.error("Confirm Password is required");
    }
    if (values.newpassword === values.confirmpassword) {
      const resp = await dispatch(
        changePassword({
          email: email,
          oldpassword: values.oldpassword,
          newpassword: values.newpassword,
        })
      );
      if (resp.payload.message === "success") {
        navigation(`/admin/dashboard`);
      }
      //   if (changePassword.fulfilled.match(resultAction)) {
      //     // Password Change successful
      //     toast.success('Password Change successfully');
      //          }
      //   else {
      //     // Password Change failed
      //     toast.error('Password Change failed frontend');
      //   }
    } else {
      toast.error("Passwords do not match");
    }
  };

  const { values, errors, touched, handleBlur, handleChange } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <h2
        style={{ fontSize: "22px", fontWeight: "bold", color: "#000" }}
        className="p-3"
      >
        <span>Change Password</span>
      </h2>
      <Card
        className="ant-shadow rounded-[15px] xl:w-[50%] lg:w-[50%] md:w-full sm:w-full xsm:w-full xxs:w-full xxxs:w-full mx-auto"
        bordered={false}
      >
        <Row justify="center">
          <Col span={24}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <Form.Item className="form-group mb-3">
                  <label htmlFor="oldPassword">Old Password:</label>
                  <Input.Password
                    className="form-control mt-2"
                    name="oldpassword"
                    placeholder="Old Password"
                    value={values.oldpassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.oldpassword && touched.oldpassword ? (
                    <p
                      className="mt-1"
                      style={{ color: "red", fontSize: "13px" }}
                    >
                      {errors.oldpassword}
                    </p>
                  ) : null}
                </Form.Item>
                <Form.Item className="form-group mb-3">
                  <label htmlFor="newPassword">New Password:</label>
                  <Input.Password
                    className="form-control mt-2"
                    name="newpassword"
                    placeholder="New Password"
                    type="password"
                    value={values.newpassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.newpassword && touched.newpassword ? (
                    <p
                      className="mt-1"
                      style={{ color: "red", fontSize: "13px" }}
                    >
                      {errors.newpassword}
                    </p>
                  ) : null}
                </Form.Item>
                <Form.Item className="form-group mb-3">
                  <label htmlFor="confirmPassword">Confirm Password:</label>
                  <Input.Password
                    className="form-control mt-2"
                    name="confirmpassword"
                    placeholder="Confirm Password"
                    type="password"
                    value={values.confirmpassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.confirmpassword && touched.confirmpassword ? (
                    <p
                      className="mt-1"
                      style={{ color: "red", fontSize: "13px" }}
                    >
                      {errors.confirmpassword}
                    </p>
                  ) : null}
                </Form.Item>
                <Form.Item className="mt-4 text-center">
                  {!loader ? (
                    <Button
                      type="submit"
                      className="bg-[#E42976] text-white border-0"
                      htmlType="submit"
                      shape="round"
                      onClick={handleSubmit}
                    >
                      Change Password
                    </Button>
                  ) : (
                    <BtnLoader />
                  )}
                </Form.Item>
              </Form>
            </Formik>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ChangePassword;
